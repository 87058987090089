import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { ApiService } from '@services/api/api.service';
import { UserRole } from '@entities/users/user-role.enum';
import { entity } from '@core/utils/rxjs';
import { AuthenticationResponse } from '@entities/authentication/authentication-response.entity';
import { AuthenticationCode } from '@entities/authentication/authentication-code.enum';

export type LoginInfo = { email: string; password: string };
export type ResetPasswordInfo = { token: string; password: string };
export interface UserToken {
  companyId: number;
  exp: number;
  iat: number;
  id: number;
  role: string;
  roleId: number;
  isMfaEnabled: boolean;
  companyFeatures: {
    featAllPaymentReport: boolean;
    featBenefits: boolean;
    featDailyPay: boolean;
    featExpenses: boolean;
    featInstantDeposit: boolean;
    featMfa: boolean;
    featPaystubs: boolean;
    featPerDiem: boolean;
    featInstantQueue: boolean;
    featInstantAutoPayment: boolean;
  };
}
export type LoginAuthenticationState = true | false | '2fa';
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  public authNotification$: BehaviorSubject<LoginAuthenticationState> = new BehaviorSubject<LoginAuthenticationState>(
    false
  );
  private mfaEmail: string = null;
  private userTokenObject: UserToken;

  public constructor(private api: ApiService, private router: Router) {
    if (this.isLoggedIn()) {
      this.userTokenObject = this.getDecodedToken();
    }
    this.authNotification$.next(this.isLoggedIn());
  }

  public login(loginInfo: LoginInfo): Observable<any> {
    return this.api.post<any>('auth/login', loginInfo).pipe(
      entity<AuthenticationResponse>(AuthenticationResponse),
      tap((resp) => {
        if (resp.is2FA()) {
          this.mfaEmail = loginInfo.email;
          this.authNotification$.next('2fa');
        }
        this.successfulLogin(resp);
      })
    );
  }

  // https://github.com/careydevelopment/carey-auth/blob/master/projects/carey-auth/src/lib/services/authentication.service.ts
  public logout(): void {
    this.clearStorage();
    this.authNotification$.next(false);
    this.router.navigate(['/login']);
  }

  public embeddedLogout(id: number): void {
    this.clearStorage();
    this.authNotification$.next(false);
    this.router.navigate(['/embedded', 'embedlogin', id]);
  }

  public isPending2FA(): boolean {
    return Boolean(this.mfaEmail);
  }

  public getMfaEmail(): string {
    return this.mfaEmail;
  }

  public resetMfaEmail(): void {
    this.mfaEmail = null;
  }

  public verifyMfaCode(email: string, code: string): Observable<any> {
    return this.api.post('auth/verify-otp', { email, otp: code }).pipe(
      entity<AuthenticationResponse>(AuthenticationResponse),
      tap(() => {
        this.mfaEmail = null;
      }),
      tap((resp: any) => {
        this.successfulLogin(resp);
      }),
      shareReplay()
    );
  }

  // POC //
  public requestOtpCode(id: number): Observable<any> {
    return this.api.post('auth/embedded-otp-send', { id }).pipe(shareReplay());
  }
  public verifyEmbeddedOtpLogin(id: number, code: string): Observable<any> {
    return this.api.post('auth/embedded-otp-login', { id, otp: code }).pipe(
      entity<AuthenticationResponse>(AuthenticationResponse),
      tap((resp: any) => {
        this.successfulLogin(resp);
      }),
      shareReplay()
    );
  }
  public navigateToEmbeddedDash(): void {
    this.router.navigate(['/embedded', 'embeddash']);
  }

  public forgotPassword(email: string): Observable<any> {
    return this.api.post('auth/forgot-password', { email }).pipe(shareReplay());
  }

  public resetPassword(resetInfo: ResetPasswordInfo): Observable<any> {
    return this.api.post('auth/reset-password', resetInfo).pipe(shareReplay());
  }

  public changePassword(currentPassword: string, newPassword: string): Observable<unknown> {
    return this.api.put('auth/change-password', { oldPassword: currentPassword, newPassword }).pipe(shareReplay());
  }

  public isLoggedIn(): boolean {
    const token = this.getToken();
    if (!token) {
      console.warn('AuthenticationService: user has no token, and is considered logged out');
      this.clearStorage();
      this.authNotification$.next(false);
      return false;
    }

    try {
      const isTokenExpired = this.isTokenExpired(token);
      if (isTokenExpired) {
        this.clearStorage();
        this.authNotification$.next(false);
        return false;
      }
    } catch (e) {
      console.warn('AuthenticationService: user has malformed token, and is considered logged out');
      this.clearStorage();
      this.authNotification$.next(false);
      return false;
    }
    return true;
  }

  public isTokenExpired(token: string): boolean {
    const expiration = this.decode(token).exp;
    if (expiration) {
      return !(Date.now() / 1000 < expiration);
    }
    return false;
  }

  public getToken(): string {
    return localStorage.getItem('paidiem_token') as string;
  }

  public getDefaultRoute() {
    switch (this.userTokenObject.roleId) {
      case UserRole.SuperAdmin:
      case UserRole.Admin:
      case UserRole.Manager:
      case UserRole.Approver:
        return ['workers'];
      case UserRole.Worker:
        return ['dashboard'];
    }
    throw new Error('Invalid role attempted to get default route');
  }

  public navigateToLandingPage(): void {
    const defaultRoute = this.getDefaultRoute();
    this.router.navigate(defaultRoute);
  }

  public clearStorage() {
    localStorage.removeItem('paidiem_token');
  }

  private decode(token: string): UserToken {
    return jwt_decode(token);
  }

  public getDecodedToken(): UserToken {
    try {
      this.userTokenObject = this.decode(this.getToken());
    } catch (e) {
      this.userTokenObject = null;
      this.clearStorage();
      throw e;
    }
    return this.userTokenObject;
  }

  private successfulLogin(authResult: AuthenticationResponse) {
    if (authResult.isLoggedIn()) {
      localStorage.setItem('paidiem_token', authResult.token);
      this.userTokenObject = this.decode(authResult.token);
      this.authNotification$.next(true);
    }
  }

  public authenticateSSO(token: string): Observable<any> {
    return this.api.post('auth/ sso-login', { token }).pipe(
      entity<AuthenticationResponse>(AuthenticationResponse),
      tap((resp) => {
        this.successfulLogin(resp);
      }),
      shareReplay()
    );
  }

  public authenticateIncoming(token: string): Observable<any> {
    const obs = of(new AuthenticationResponse({ token, code: AuthenticationCode.LoggedIn }));
    return obs.pipe(
      entity<AuthenticationResponse>(AuthenticationResponse),
      tap((authEntity) => {
        if (this.isTokenExpired(token)) {
          this.clearStorage();
          throw new Error('Inbound token is expired');
        }
        try {
          this.decode(token);
          this.successfulLogin(authEntity);
        } catch (e) {
          this.clearStorage();
          throw e;
        }
      }),
      shareReplay()
    );
  }

  public checkIfTokenValid(token: string): Observable<any> {
    return this.api.get('auth/pd-token-validation', { params: { token } }).pipe(shareReplay());
  }
}
